.rvsp_container {
  height: calc(100vh - 72px);
}

.rvsp_image {
  height: 100%;
  width: 40%;
  object-fit: cover;
}

.components_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 60%;
  padding: 0 6rem;
}

.rvsp_title {
  font-size: 45px;
  color: #d4af37;
}

.rvsp_header {
  display: flex;
  gap: 0.6rem;
  flex-direction: column;
}

.rvsp_info {
  font-size: 15px;
}

.rvsp_double_form {
  display: flex;
  gap: 4rem;
}

.rvsp_double_form > * {
  flex-basis: 50%;
}

.rvsp_single_form {
  display: flex;
}

.rvsp_single_form > * {
  flex-basis: 100%;
}

.rvsp_container input {
  font-family: "Marcellus", serif;
}

.rvsp_container label {
  font-family: "Marcellus", serif;
}

.rvsp_container label.Mui-focused {
  color: #d4af37;
}

.rvsp_container .MuiInput-root:hover::before {
  border-bottom: 2px solid #8e7627 !important;
}

.rvsp_container .MuiInput-root::after {
  border-bottom: 2px solid #d4af37;
}

.rvsp_container .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #8e7627;
}

.rvsp_container
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #d4af37;
}

.rvsp_form_container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.sent_button.MuiButton-root {
  width: 5rem;
  margin-left: auto;
  font-family: "Marcellus", serif;
  letter-spacing: 1.5px;
  background-color: #d4af37;
  box-shadow: unset !important;
}

.sent_button.MuiButton-root:hover {
  background-color: #d4af37;
  opacity: 0.8;
}

.rvsp_others * {
  font-family: "Marcellus", serif !important;
}

@media screen and (max-width: 992px) {
  .components_container {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 768px) {
  .rvsp_image {
    display: none;
  }

  .components_container {
    width: 100%;
    justify-content: flex-start;
  }

  .rvsp_container {
    height: unset;
    padding-bottom: 4rem;
  }

  .rvsp_double_form {
    gap: 1rem;
    flex-direction: column;
  }
}
