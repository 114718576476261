.invitation-container {
  padding: 4rem 3rem;
  flex-direction: column;
  gap: 4rem;
  height: 65vh;
  justify-content: space-evenly;
}

.introduction-container {
  letter-spacing: 5px;
  color: #232323;
  gap: 6px;
  text-align: right;
}

.invitation-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.invitation-info-header {
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
}

.names {
  font-size: 4rem;
  letter-spacing: 10px;
  font-weight: 200;
  color: #232323;
  text-align: center;
}

.date {
  font-size: 2rem;
  color: #d4af37;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media screen and (max-width: 768px) {
  .introduction-container {
    font-size: 0.8rem;
  }

  .names {
    font-size: 2rem;
  }

  .date {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .invitation-container {
    padding: 4rem 1rem;
  }
}
