*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Marcellus", serif;
}

body, html {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Buongiorno;
  src: url('../public/fonts/Buongiorno-Rastellino.otf');
}

@font-face {
  font-family: BeautifullyDelicious;
  src: url('../public/fonts/BDScript-Regular.otf');
}

.font-Cinzel {
  font-family: 'Cinzel';
}

.container{
  background-color: white;
  z-index: 2;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.quotes {
  font-family: 'BeautifullyDelicious';
  font-size: 4.5rem;
  text-align: center;
  color: #232323;
}

@media screen and (max-width: 768px) {
  .quotes {
      font-size: 2.5rem;
  }
}
