.block_slider_fixed{
  height: 100vh;
  position: relative;
}

.block_mask{
  position: absolute;
  width: 100%;
  height: 100vh;
}

.block_content{
  position: fixed;
  top: 0;
}

#hero_slideshow {
  height: 100vh;
  overflow: hidden;
}

#hero_slideshow .slide {
  position: relative;
  height: 100%;
  width: 100vw;
}

#hero_slideshow .slide_active,
#hero_slideshow .slide_last {
  height: 100%;
  display: flex;
}

#hero_slideshow .slide_active img,
#hero_slideshow .slide_last img {
  height: 100%;
  width: 100vw;
  object-fit: cover;
  object-position: top;
  filter: grayscale(1);
}

#hero_slideshow.small_screen .slide_active img,
#hero_slideshow.small_screen .slide_last img {
  object-position: bottom;
}

#hero_slideshow .slide_active {
  transition: transform 1s ease-in-out;
}

#hero_slideshow .slide_last {
  transition: unset;
}

#hero_slideshow .slide_overlay {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  bottom: 0;
  width: 100%;
  padding: 2rem;
}

#hero_slideshow .slide_overlay h2,
#hero_slideshow .slide_overlay h3 {
  text-align: center;
  color: red;
}

#hero_slideshow .slide_overlay h2 {
  font-size: 2.5rem;
  font-weight: 200;
}

#hero_slideshow .slide_overlay h2.size_M {
  font-size: 1.75rem;
}

#hero_slideshow .slide_overlay h3.size_M {
  font-size: 1rem;
  margin: 0;
}
