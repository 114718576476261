.countdown_section{
    justify-content: center;
    height: 0;
}

.countdown_container{
    background-color: #1e1e1e59;
    padding: 25px;
    position: absolute;
    transform: translateY(-150%);
    width: 450px;
    max-width: 90vw;
    display: flex;
    justify-content: space-between;
    color: white;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.countdown_item{
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
}

.countdown_item_number{
    font-size: 2rem;
}

.countdown_item_label{
    text-transform: uppercase;
    font-size: 0.6rem;
    letter-spacing: 1px;
}