.nav_bar {
  background-color: transparent;
  box-shadow: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  color: white;
  height: 72px;
  transition: background-color 0.3s ease;
}

.nav_bar.scrolled{
  background-color: white !important;
  color: black;
}

.nav_bar.scrolled .divider{
  background-color: black;
}

.divider{
  width: 1.7px;
  height: 75%;
  background: white;
}

.logo {
  font-size: 1.4rem!important;
  font-weight: bold !important;
  display: flex;
  align-items: center !important;
  gap: 10px;
  height: 100%;
}

.header_item_container{
  display: flex;
  gap: 60px;
  font-size: large;
}

.header_item {
  cursor: pointer;
  padding: 10px 0;
  position: relative;
  font-size: 0.9rem;
  letter-spacing: 3px;
}

.header_item::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: white;
  transition: width 0.2s ease;
}

.nav_bar.scrolled .header_item::before {
  background-color: black;
}

.header_item:hover::before {
  width: 100%;
}

.logo_item{
  font-size: 0.8rem;
}