.main_container {
  background-color: #f8f6f4;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 50px;
  flex-direction: column;
  gap: 50px;
  height: calc(100vh - 72px);
}

.title_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.locations_title {
  font-size: 30px;
  display: flex;
  justify-content: center;
  letter-spacing: 5px;
  margin: 20px 0 10px 0;
}

.locations_underline {
  width: 100%;
}

.location_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  width: 100%;
  padding: 0 15px;
  margin: 35px 0;
}

.location_container img {
  width: 500px;
  height: 390px;
  object-fit: cover;
}

.details_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.pretitle {
  font-family: "BeautifullyDelicious";
  font-size: 3.5rem;
  line-height: 0.8;
  color: #d4af37;
}

.title {
  font-size: 0.9rem;
  letter-spacing: 3px;
  color: #0000008f;
  text-decoration: none;
}

.underline {
  width: 50%;
}

.location_details_description {
  max-width: 230px;
  font-size: 0.8rem;
  text-align: center;
  opacity: 0.8;
}

.location_time {
  font-size: 1.1rem;
  font-style: italic;
}

.icon {
  width: 3.5rem;
  height: 3.5rem;
}

.location_container iframe {
  flex: 1 0 50%;
  max-width: 450px;
  min-height: 350px;
}

@media screen and (max-width: 768px) {
  .main_container {
    height: unset;
    padding: 5rem 10px;
  }
}
