.image-slider-container {
  width: 100%;
  height: calc(100vh - 72px);
  overflow: hidden; /* Ensure no scrollbars appear */
  position: relative; /* Ensure child elements position relative to this container */
}

.slick-slider {
  width: 100vw;
  height: 100%;
  filter: brightness(0.8);
}

.slick-slider div {
  width: 100%;
  height: 100%;
}

.slick-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.slick-dots {
  bottom: 10px !important; /* Optional: Adjust the dots position */
}

.slick-prev,
.slick-next {
  font-size: 24px;
  color: #ffffff;
}

.slick-dots li button:before {
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white;
}
