.footer_quote{
    padding: 7rem 0;
    justify-content: center;
}

.footer_container{
    flex-direction: column;
}

.bottom_container{
    background-color: #010302;
    padding: 4rem 8rem 1rem 8rem;
}

.bottom_container_content{
    width: 100%;
    max-width: 1140px;
}

.footer_divider{
    width: 1.7px;
    height: 75%;
    background: white;
}
  
.footer_logo {
    font-size: 2rem!important;
    display: flex;
    align-items: center !important;
    gap: 13px;
    height: 100px;
    color: white;
    padding-bottom: 3rem;
}

.footer_info{
    color: white;
    padding-bottom: 0.5rem;
}

.footer_phone_numbers{
    color: #bab9b9;
    padding-bottom: 0.3rem;
    font-size: 14px;
}

.footer_underline{
    width: 100%;
    margin-top: 3rem;
}

.copyright{
    color: #848484;
    font-size: 13px;
    padding-top: 1rem;
    border-top: 1px solid white;
    margin-top: 3rem;
}

@media screen and (max-width: 992px) {
    .bottom_container{
        padding: 2rem 2rem 1rem 2rem;
    }
}


